<template>
  <div class="page_bg error_page" style="background: #FFFFFF;">
    <van-image width="10rem" height="10.93rem"
               src="https://oss.xiaoyi120.com/wholesale/error_01.png"
    />
    <div class="error_page_tip">暂无内容</div>
    <div class="error_page_msg">您的身份无法访问优选商城哦~</div>
    <div class="error_page_btn flex-r-sa-c">
      <div />
      <van-button round block plain type="info" native-type="submit" style="font-size: 0.4rem;" @click="openKeFu">咨询客服
      </van-button>
      <van-button round block type="info" native-type="submit" color="#0767ab" style="font-size: 0.4rem;"
                  @click="reLogin"
      >重新登录
      </van-button>
      <div />
    </div>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
import kefuBtn from '@/components/kefu_btn'
export default {
  components: { kefuBtn },
  data() {
    return {

    }
  },
  methods: {
    reLogin() { this.$router.push('/user/login') },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) }
  }
}
</script>

<style lang="scss">
.error_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .van-image {
  }
  &_tip {
    font-size: 0.48rem;
    color: #333333;
    margin-top: 0.11rem;
  }
  &_msg {
    font-size: 0.37rem;
    color: #999999;
    margin-top: 0.11rem;
  }
  &_btn {
    margin-top: 1rem;
    width: 100%;
    .van-button{
      width: 3.2rem;
    }
  }
}
</style>
